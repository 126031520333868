export const ADDRESSES = 'addresses';
export const ARTWORKS = 'artworks';
export const ARTWORK_TRANSFERS = 'artworkTransfers';
export const ASSET_PAIRS = 'assetPairs';
export const AUTHENTICATION = 'authentication';
export const CART_PRODUCTS = 'cartProducts';
export const COMMENTS = 'comments';
export const CONTRIBUTIONS = 'contributions';
export const CONTRIBUTIONS_CUMULATIVE = 'contributions_cumulative';
export const CONTRIBUTORS = 'contributors';
export const CONVERSATIONS = 'conversations';
export const CORES = 'cores';
export const COURSES = 'courses';
export const EXCHANGE_ORDERS = 'exchangeOrders';
export const FOLLOWERS = 'followers';
export const FOLLOWINGS = 'followings';
export const IA = 'ia';
export const INVITATIONS = 'invitations';
export const INVITATION_LIMITS = 'invitationLimits';
export const LECTURES = 'lectures';
export const MANAGER = 'manager';
export const MESSAGES = 'messages';
export const NOTIFICATIONS = 'notifications';
export const ORDERS = 'orders';
export const POSTS = 'posts';
export const PRODUCTS = 'products';
export const SELF = 'self';
export const TRADES = 'trades';
export const USERS = 'users';
export const USER_STATS = 'userStats';
export const WALLETS = 'wallets';
export const WIRES = 'wires';
