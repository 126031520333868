import styled from 'styled-components';

export const DropdownMenuContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const InvitationStatusBadgeContainer = styled.div`
  display: flex;
`;
